























import { defineComponent } from '@vue/composition-api';
import { useGitHubStore } from '@/stores/github';
import { storeToRefs } from 'pinia';

export default defineComponent({
  name: 'GithubConnection',
  setup() {
    const githubStore = useGitHubStore();

    const { isInstallationSuspended, getInstallationStatusAsString, getInstallationUrl } = storeToRefs(githubStore);

    if (isInstallationSuspended.value) {
      githubStore.suspendInstallation();
      githubStore.continueAfterConnected();
    }

    return {
      isInstallationSuspended,
      getInstallationStatusAsString,
      getInstallationUrl,
    };
  },
});
